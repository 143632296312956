import { HttpClient, HttpParams } from '@angular/common/http';
import { environment } from '../../../../../environments/environment';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import {
  ComplianceRequirementCategoryDTO,
  ComplianceRequirementCategoryFormDTO,
} from '../dto/compliance-requirement-category.dto';

@Injectable({
  providedIn: 'root',
})
export class ComplianceRequirementCategoryService {
  private apiUrl = environment.apiUrl; // Use the apiUrl from environment

  constructor(private http: HttpClient) {}

  list(
    sortField: string = 'title_en',
    sortOrder: string = 'asc',
    searchQuery?: string
  ): Observable<ComplianceRequirementCategoryDTO[]> {
    let params = new HttpParams()
      .set('sort_by', sortField)
      .set('sort_order', sortOrder);

    if (searchQuery) {
      params = params.set('keyword', searchQuery);
    }

    return this.http.get<ComplianceRequirementCategoryDTO[]>(
      this.apiUrl + '/compliance-requirement-categories',
      {
        params,
      }
    );
  }

  getComplianceRequirementCategory(
    id: number
  ): Observable<ComplianceRequirementCategoryDTO> {
    return this.http.get<ComplianceRequirementCategoryDTO>(
      `${this.apiUrl}/compliance-requirement-categories/${id}`
    );
  }

  addComplianceRequirementCategory(
    complianceRequirementCategory: ComplianceRequirementCategoryFormDTO
  ): Observable<ComplianceRequirementCategoryFormDTO> {
    return this.http.post<ComplianceRequirementCategoryFormDTO>(
      this.apiUrl + '/compliance-requirement-categories',
      complianceRequirementCategory
    );
  }

  updateComplianceRequirementCategory(
    id: number,
    complianceRequirementCategory: ComplianceRequirementCategoryFormDTO
  ): Observable<ComplianceRequirementCategoryFormDTO> {
    return this.http.put<ComplianceRequirementCategoryFormDTO>(
      `${this.apiUrl}/compliance-requirement-categories/${id}`,
      complianceRequirementCategory
    );
  }

  activateComplianceRequirementCategories(ids: number[]): Observable<any> {
    return this.http.put(
      `${this.apiUrl}/compliance-requirement-categories/activate`,
      { ids: ids }
    );
  }

  deactivateComplianceRequirementCategories(ids: number[]): Observable<any> {
    return this.http.put(
      `${this.apiUrl}/compliance-requirement-categories/deactivate`,
      { ids: ids }
    );
  }

  deleteComplianceRequirementCategories(ids: number[]): Observable<any> {
    return this.http.post(
      `${this.apiUrl}/compliance-requirement-categories/delete`,
      { ids: ids }
    );
  }
}
